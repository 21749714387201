import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';

import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { SITE_URL } from '@/utils/constants';

import Layout from '@/components/layout';
import Faq from '@/components/Faq/AccordionSidebarFaq';
import LowRateMicrositeHeader from './_low-rate-header';
import PageData from './data/low-rate-data.json';

const Faqs = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 130px !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
          div[class^='lfs-pageheader-item']{
            padding: 40px 0;
          }  
        `}
      >
        <Metadata
          title={`Low Rate FAQs | Low Rate Mastercard`}
          description={`Learn all the handy hints, tips, and answers for everything you wanted to know about your Low rate Card`}
          keywords="FAQ"
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <LowRateMicrositeHeader />
        <SecondaryHero
          style={'padding-top: 80px;'}
          heading="Frequently Asked Questions"
          subHeading="Got a burning question? You may find your answer here."
        />

        <Faq data={PageData.faqs} />
      </main>
    </Layout>
  );
};

export default Faqs;
